
.bouh{
  color: blue !important;
  font-weight:bold;
}

.example-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

#myGrid {
  flex: 1 1 0px;
  width: 100%;
}

.green {

  background-color: #dbf6f3 !important;
}

.red {
  background-color: #f6dbde !important;
}


.blue{
    background-color: #dbecf6 !important;
}

.ag-theme-alpine .ag-cell{

  padding-left: 5px;
  padding-right: 5px;
}

.numeric-input {
  box-sizing: border-box;
  padding-left: var(--ag-grid-size);
  width: 100%;
  height: 100%;
}

 .ag-theme-alpine .ag-header-background-color{
    /* customise with CSS variables */
   
    background-color: #000;

}

.ag-theme-alpine .ag-header{
    background-color: white;
}